{
  "name": "gugudata-io-portal",
  "version": "1.0.17",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port=8100",
    "start:cn": "ng serve --port=8110 -c zh",
    "build": "ng build --aot && npm run sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test --no-progress",
    "i18n": "ng extract-i18n --output-path src/locale",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org gugudata --project gugudata-io-portal ./dist && sentry-cli sourcemaps upload --org gugudata --project gugudata-io-portal ./dist",
    "serve:ssr:gugudata-io-portal": "node dist/server/server.mjs",
    "build:ssr": "ng build --configuration production"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.3.1",
    "@angular/common": "17.3.1",
    "@angular/compiler": "17.3.1",
    "@angular/core": "17.3.1",
    "@angular/forms": "17.3.1",
    "@angular/platform-browser": "17.3.1",
    "@angular/platform-browser-dynamic": "17.3.1",
    "@angular/platform-server": "17.3.1",
    "@angular/router": "17.3.1",
    "@angular/ssr": "^17.3.1",
    "@sentry/angular-ivy": "^7.99.0",
    "@sentry/cli": "^2.28.0",
    "@stripe/stripe-js": "^3.3.0",
    "@vercel/speed-insights": "^1.0.10",
    "angular-oauth2-oidc": "^17.0.2",
    "express": "^4.18.2",
    "highlight.js": "^11.9.0",
    "ngx-highlightjs": "^11.0.0-beta.1",
    "ngx-skeleton-loader": "^9.0.0",
    "ngx-stripe": "^17.2.0",
    "ngx-turnstile": "^0.2.1",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.1",
    "@angular/cli": "17.3.1",
    "@angular/compiler-cli": "17.3.1",
    "@angular/localize": "17.3.1",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "bootstrap": "^5.2.2",
    "bootstrap-icons": "^1.9.1",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
